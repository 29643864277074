//import business1 from "./images/details-1.jpg";
//import business2 from "./images/details-2.jpg";
//import business3 from "./images/details-3.jpg";
//import business4 from "./images/details-4.jpg";
import businessffx1 from "./images/fastfix01.jpg";
import businessepos1 from "./images/epostal.jpg";
import businessepos2 from "./images/epostal2.jpg";
import businessepos3 from "./images/epostal3.jpg";
/* import businessrest1 from "./images/rest1.jpg";
import businessrest2 from "./images/rest2.jpg";
import businessrest3 from "./images/rest3.jpg"; */
import businesscoffee from "./images/coffee.jpg";
import businessautorep1 from "./images/autorep1.jpg";
import businessautorep2 from "./images/autorep2.jpg";
import businessautorep3 from "./images/autorep3.jpg";
import businessautomaint1 from "./images/automaint1.jpg";
import businessautomaint2 from "./images/automaint2.jpg";
import businessautomaint3 from "./images/automaint3.jpg";
import img1 from "./images/business-1.jpg";
// import img3 from "./images/business-3.jpg";
import img4 from "./images/business-4.jpg";
import img5 from "./images/business-5.jpg";
import img6 from "./images/business-6.jpg";
// import img7 from "./images/business-7.jpg";
//import img7 from "./images/business-8.jpg";
import img8 from "./images/business-9.jpg";
import img9 from "./images/business-10.jpg";
import img10 from "./images/business-11.jpg";
import img11 from "./images/business-12.jpg";
/* import img13 from "./images/business-13.jpg";
import img13A from "./images/business-13A.jpg";
import img13B from "./images/img13B.jpg"; */
import business14 from "./images/business-14.jpg";
import business15 from "./images/business-15.jpg";
import business15B from "./images/business-15B.jpg";
import business15A from "./images/business-15A.jpg";
import business16 from "./images/business-16.jpg";
import business16A from "./images/business-16A.jpg";
import business17 from "./images/business-17.jpg";
import business18 from "./images/business-18.jpg";
// import business19 from "./images/business-19.jpg";
import business20 from "./images/business-20.jpg";
import business212 from "./images/business-212.jpg";
import business22 from "./images/business-22.jpg"; 
import business23 from "./images/business-23.jpg";
import business25 from "./images/business-25.jpg";
import business25a from "./images/business-25a.jpg";
import business25b from "./images/business-25b.jpg";
import business255 from "./images/business255.jpg";
import business266 from "./images/business-266.jpg";
import business26a from "./images/business-26a.jpg";
import business26b from "./images/business-26b.jpg";
import business26aa from "./images/business-266-2a.jpg";
import business288 from "./images/business-288.jpg";
import business288a from "./images/business-288a.jpg";
import business288b from "./images/business-288b.jpg";
import business288c from "./images/business-288c.jpg";
import business288d from "./images/business-288d.jpg";
import business288e from "./images/business-288e.jpg";
import business300 from "./images/business-300.jpg"
import business301 from "./images/business-301.jpg";
import business301a from "./images/business-301a.jpg";

import databiz from "./images/databiz.png";
import nopx from "./images/nopx.jpg";
//import { isNonNullExpression } from "typescript";
// import img12 from "./images/business-12.jpg";

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    sys: {
      id: "266"
    },
    fields: {
      name: "Pizza Restaurant 2nd Generation Space - Plano (North Dallas) TX",
      slug: "restaurantItalian2",
      type: "restaurant",
      status: "",
      active: 1,
      sold: "",
      otr: "",
      onhold: "",
      price: 95000,
      adlink: "",
      // adlink: "https://www.bizbuysell.com/Business-Opportunity/pizza-restaurant-for-sale-plano-north-dallas-tx/2251455/",
      adlinklabel: `BizBuySell, BizQuest, LoopNet`,
      ndalink: "https://www.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=bb63b99b-5fec-40d0-a071-bc7ec72fc5be&env=na1&acct=b0ae5c00-9705-4ddb-b3c9-7eda5fe928ce&v=2",
      ndalinklabel: "NDA link",
      grosssales: 628200,
      cashflow: "",
      inventoryatcost: 5000,
      mthlybuildingrent: 6180.75,
      typeoffinancial: "Seller Financial Statements",
      reportperiodoffinancials: 3,
      ffe: 85000,
      size: "1728" ,
      established: 7,
      eqValuation: false,
      busValuation: false,
      featured: false,
      relocatable: false,
      homebased: false,
      realestateincluded: false,
      reasonforsale: "absentee owners desire to divest",
      description:
        ["* inventory not included in Ad price",
        "",
        "BUSINESS DESCRIPTION", 
         "Opportunity to purchase turn-key upscale pizza restaurant facility in a prime north Dallas location just off of the North Dallas Tollway near Willow Bend Mall in Plano, TX. The current concept is based on an authentic Neapolitan pizza prepared in a wood burning oven. Very clean operation that shows very well. The other value is that the seller is willing to keep the business open for a period of time while the buyer makes a quick changeover with the idea to maintain the continuity and synergy of the $600K year gross revenues the business has been generating.",
         "PRICED TO SELL QUICKLY!! The name, concept and recipes are not included as they are currently licensed by a third party far away from the trade area in another city. Excellent location/demographic with all interior finish out, equipment and a fair premises lease in place for an operator to establish themselves with way less than the cost of building one from the ground up. NOTE:. Also licensed to sell liquor, beer & wine",
         "Any closer looks at the business or meetings with the owner are to be done after hours by appointment only through the broker who has all of the necessary information. For further info contact owner’s representative Stocker Travis at 214-233-6481 or email to Stocker@dbi-businessadvisors.com.",
        "FACILITIES",
         "Ideal situation for an individual or pizza chain looking for a fully equipped, finished out restaurant in a great location to launch or expand on a pizza concept or established name brand without having to invest the hundreds of thousands or dollars it normally takes to do so. To replicate the physical facility with respect to equipment, finish out and leasehold improvements and to maintain operations for at least 6 months before turning a profit would cost well over $500,000",
        "COMPETITION",
        "Normal within 5 mile radius trade area",
        "GROWTH AND EXPANSION",
        "Excellent for the right promotion minded, forward thinking individual.",
        // "SUPPORT AND TRAINING",
        // "The proprietary concept & menu will have to be changed so the seller cannot provide any on-site training after the purchase but can assist in securing retention of employees and transfer of supplier relationships as well as provide “on-call” telephone assistance regarding operations and sign a no competition agreement.",
      ],

      extras: [
        ""
      ],
      images: [
        {
          fields: {
            file: {
              url:  business266
            }
          }
        },
        {
          fields: {
            file: {
              url: business26aa
            }
          }
        },
        {
          fields: {
            file: {
              url: nopx
            }
          }
        },
        {
          fields: {
            file: {
              url: nopx
            }
          }
        }
      ]
    }
  },
  {
    sys: {
      id: "19"
    },
    fields: {
      name: "Cattle Roping Dummy Manufacturer",
      slug: "smallmanufacturer",
      type: "other",
      status: "",
      active: "1",
      sold: "",
      otr: "",
      onhold: "",
      price: 175000,
      adlink: "https://www.bizquest.com/business-for-sale/roping-dummy-manufacturer-for-sale-dallas-fort-worth-metroplex/BW2294168/",
      adlinklabel: `BizBuySell, BizQuest, LoopNet`,
      ndalink: "https://www.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=199c5746-238f-466f-9bfb-047e93a3a534&env=na1&acct=b0ae5c00-9705-4ddb-b3c9-7eda5fe928ce&v=2",
      grosssales: 114338,
      cashflow: "",
      inventoryatcost: "",
      mthlybuildingrent: 0,
      typeoffinancial: "Seller Financial Statements",
      reportperiodoffinancials: 1,
      ffe: 60000,
      size: 0,
      established: 16,
      eqValuation: true,
      busValuation: false,
      featured: false,
      relocatable: true,
      homebased: true,
      realestateincluded: false,
      reasonforsale: "Owners losing key employees (son and daughter) leaving for college",
      description:
        ["Home Based Business", 
         "Opportunity to purchase a manufacturing operation that makes roping dummies for the rodeo industry (brochure or “look book” available upon request). These are sold wholesale to retail distributors that primarily include farm and ranch type businesses in the state of Texas who sell to the end user. Products include three sizes of dummies and other related accessories. Current customers and organizations the products are being sold to include farm & ranch supply stores, western apparel stores, rodeo & roping supply stores, feed & supply stores, a large agriculture cooperative and other assorted retail stores. No contracts exist limiting how & where the owner may sell the products. Extreme potential & opportunity to offer the product directly to the end user via an on line presence which currently does not exist. The margins are sufficient enough for a buyer to realize significant profits. Depending on the size (mini, micro or standard), the cost to produce the product ranges from $20-$55 with a wholesale price ranging from $45-$210 and a retail on-line price ranging from $65-$300). August to February is typically the peak production time. A trademark for the product is also included in the sale.",
         "The business can be run by one person working approximately 20 hours per week who can easily perform all tasks including cutting of the PVC pipe, painting, all assembly and packaging/shipping. There are established processes in place that make assembly very easy especially since most are built in batches.",
         "Sale includes all work in progress and raw materials on hand the day of possession, which may fluctuate depending on current orders at the time. Easy accounting with 90% of the sales receipts being electronic transfers and the remainder being checks. Purchaser will need to decide to keep a salesman who is not very active that is a manufacturer’s rep and receives a 10% commission on all products sold to certain customers.",
         "The buyer needs to reach out beyond Texas where the majority of the products are now sold & delivered. There is an untapped market to be reached that should increase sales substantially. The current owners have been contacted by feed stores and tack shops from Canada, Idaho, Louisiana and Oklahoma interested in carrying the products in their stores. Shipping costs to do that have proven to be too high and current ownership does not have the manpower or time to be able to deliver to those markets.",
         "NOTE: The sales revenue and EBITDA info stated above are for the 15 months ended July 31, 2024",
         "Any closer looks at the business or meetings with the owner are to be done on weekends by appointment only through the broker who has all of the necessary information.",
         "FACILITIES & ASSETS",
         "Sale also includes a 2016 Dodge Ram 3500 dual crew cab pickup truck, 2 trailers, various drill presses, shelving, storage racks, bins & various tools (equipment list available upon request).",
         "WEBSITE",
         "https://www.dragsteer.net/",
         "MARKET OUTLOOK/COMPETITION",
         "Quite a few similar products offered on the internet however this product is a bit higher in quality and also competitive on price.",
         "OPPORTUNITIES FOR GROWTH",
         "There is considerable room for growth and expansion for a buyer who understands e-commerce. In addition to the obvious benefits of establishing an on-line retail presence, it needs to expand upon its social media presence as well which is currently quite small.",
         "TRANSITION SUPPORT",
         "Seller will provide initial on-site training after the purchase, and assist with transfer of supplier relationships, provide on-call telephone assistance after completion of the training and sign a no competition agreement."],
      extras: [
        ""
      ],
      images: [
        {
          fields: {
            file: {
              url: business212
            }
          }
        }, 
        {
          fields: {
            file: {
              url: nopx
            }
          }
        },
        {
          fields: {
            file: {
              url: nopx
            }
          }
        },
        {
          fields: {
            file: {
              url: nopx
            }
          }
        }
      ]
    }
  },
  {
    sys: {
      id: "2"
    },
    fields: {
      name: "retail-eyewear + optometrist office",
      slug: "retail",
      type: "retail-eyewear",
      status: "",
      active: "",
      sold: "",
      otr: "",
      onhold: "",
      offmarket: 1,
      price: 175000,
      adlink: "",
      adlinklabel: `BizBuySell, BizQuest, LoopNet`,
      ndalink: "",
      grosssales: 464430,
      cashflow: 52500,
      inventoryatcost: 30000,
      mthlybuildingrent: 4275,
      typeoffinancial: "Seller Financial Statements",
      reportperiodoffinancials: 3,
      ffe: 130000,
      size: 1900,
      established: 20,
      eqValuation: false,
      busValuation: false,
      featured: false,
      relocatable: false,
      homebased: false,
      realestateincluded: false,
      reasonforsale: "retirement",
      description:
        ["Opportunity to purchase a well established Pearle Vision franchise which is an American chain of eye care stores and one of the largest franchised optical retailers in the country. This particular store is one of the oldest franchise locations in the Dallas/Fort Worth metroplex. It dispenses prescription eyeglasses, sunglasses, and contact lenses and has an on-site finishing laboratory that allows the licensed operator to produce a complete pair of eyeglasses on site for a significant percentage of prescriptions. This store sells non-prescription sunglasses, contact lens solution, accessories, and other ancillary items (such as reading glasses and magnifying glasses) that are approved by the franchisor. As part of the licensed eye care center, franchisees may also offer optometric services through a licensed optometrist or medical doctor. Franchisees may also become a provider for managed vision care insurance plans such as EyeMed Vision Care, LLC, one of the franchisor’s service affiliates.",
      "Store is clean and shows very well. Seller has been keeping the store up to speed improvements wise so there should be no upgrade requirements from the franchiser when transferring the Pearle Vision franchise agreement. All equipment is in perfect condition and is owned outright by the seller. Fully equipped optometrist office set up and ready to go just next door to the retail store. Having the optometrist practice and optician/retail operation side by side is the Pearle Vision typical business model that has proven to be extremely successful.",
      "Very limited competition. Pearle Vision is now operating 110 corporate stores and has 445 franchises throughout North America and after acquisition by the European conglomerate Essilor Luxottica in 2004, became the largest global player in the eyewear industry. Essilor Luxottica contributed numerous proprietary lens technologies as well as the brands Foster Grant, Costa Del Mar, Ray Ban, Oakley, Persol, Oliver Peoples and Vogue Eyewear plus eyewear retailers Lens Crafters and Sunglass Hut. They also have ownership of the eyewear insurance company EyeMed.",
      "The ideal buyer who could reap the most benefit would be a licensed optometrist or medical doctor. No royalties are paid on revenues generated by the doctor. The seller of this unit had a doctor on site for 16+ years who left due to a medical situation 4 years ago causing the store’s profitability to decrease substantially. ",
      "Seller & franchiser will provide on-site training after the purchase, assist in securing retention of employees and transfer of supplier relationships, provide on-call telephone assistance after completion of the training and sign a no competition agreement.",
      "This business is an established franchise."
      ],
      extras: [
        ""
      ],
      images: [
        {
          fields: {
            file: {
              url: business255
            }
          }
        },
        {
          fields: {
            file: {
              url: business25
            }
          }
        },
        {
          fields: {
            file: {
              url: business25a
            }
          }
        },
        {
          fields: {
            file: {
              url: business25b
            }
          }
        }
      ]
    }
  },
  {
    sys: {
      id: "300"
    },
    fields: {
      name: "high volume hamburger restaurant",
      slug: "restaurant-hamburger",
      type: "restaurant",
      status: "",
      active: 1,
      sold: "",
      otr: "",
      onhold: "",
      offmarket: "",
      price: 375000,
      adlink: "https://www.bizbuysell.com/Business-Opportunity/High-Volume-Hamburger-Fast-Food-Restaurant-for-Sale-Ellis-County-TX/2273827",
      adlinklabel: `BizBuySell, BizQuest, LoopNet`,
      ndalink: "https://www.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=8d68c6d0-22e2-43df-938e-2f5577987a06&env=na1&acct=b0ae5c00-9705-4ddb-b3c9-7eda5fe928ce&v=2",
      grosssales: 728522,
      cashflow: 91571,
      inventoryatcost: 7500,
      mthlybuildingrent: 5750,
      typeoffinancial: "Seller Financial Statements",
      reportperiodoffinancials: 3,
      ffe: 50000,
      size: 2400,
      established: 10,
      eqValuation: false,
      busValuation: false,
      featured: false,
      relocatable: false,
      homebased: false,
      realestateincluded: false,
      reasonforsale: "retirement",
      description:
        ["BUSINESS DESCRIPTION",
          "Opportunity to purchase a high volume hamburger/fast food restaurant in a niche market located in a fast growing community south of downtown Dallas.  Long established well known operation that has developed a large following with a name that has reached somewhat of a brand status and is widely recognized in Ellis County.  Defintely a destination oriented operation.  The food is fresh and way above average (was recently publicly recognized as the best burger in north Texas!).  The business survived and thrived through the COVID pandemic as well as 2.5 years of road construction which was finally completed in late 2022.",
          "The owner got the idea of converting the business to a cruise-in concept in a more suitable location. He tried a test promotion called Thursday-Night Cruise-In which was a huge success as it quickly outgrew the parking space available at the time which was eventually converted to public use only.  All these plans have changed as the owner has recently started to experience some health issues and has decided to sell and retire from the business.  Now is the time for a buyer with the proper vision to cash in on the successful brand the owner has created and eventually upgrade and expand the business to a classic car drive-up car hop concept including inside dining in another location suitable for a much higher volume.",
          "FACILITIES",
          "Sale includes all furniture, fixtures, and equipment necessary to run a high volume hamburger restaurant plus inventories & supplies on hand, the trade name, all social media currently in place, all of the Seller’s rights under the current premises lease, the telephone number and the goodwill of the business as a going concern. Currently located in a 2,400 square foot free standing building with 94 seats inside plus another 46 outside.",
          "COMPETITION",
          "Market for this type of fast food business remains strong. Reasonably priced, excellent product. Limited competition in the immediate trade area. The competition that does exist has a hard time competing with their product and reputation.",
          "GROWTH AND EXPENSION",
          "Excellent for the right individual. The owner was planning on buying enough real estate or a second generation restaurant with ample parking to move the operation and expand the concept to a car hop service similar to Keller’s Hamburgers/Drive In in Dallas, Mel’s Diners in California (pictured)and Big Boy’s Drive In. The “cruise in” aspect really appeals to classic car and motorcycle enthusiasts which contributed to making the original business model so popular in the first place.",
          "SUPPORT AND TRAINING",
          "Seller will provide on-site training after the purchase, assist in securing retention of employees and transfer of supplier relationships, provide on-call telephone assistance after completion of the training and sign a no competition agreement."

      ],
      extras: [
        ""
      ],
      images: [
        {
          fields: {
            file: {
              url: business300
            }
          }
        },
        {
          fields: {
            file: {
              url: nopx
            }
          }
        },
        {
          fields: {
            file: {
              url: nopx
            }
          }
        },
        {
          fields: {
            file: {
              url: nopx
            }
          }
        }
      ]
    }
  },
  {
    sys: {
      id: "301"
    },
    fields: {
      name: "fast food restaurant arlington",
      slug: "restaurant-fast food-arlington",
      type: "restaurant",
      status: "",
      active: "",
      sold: "1",
      otr: "",
      onhold: "",
      offmarket: "",
      price: 75000,
      adlink: "",
      adlinklabel: ``,
      ndalink: "",
      grosssales:195000,
      cashflow: 43400,
      inventoryatcost: 3000,
      mthlybuildingrent: 4551,
      typeoffinancial: "Seller Financial Statements",
      reportperiodoffinancials: 2,
      ffe: 30000,
      size: 1423,
      established: 7,
      eqValuation: false,
      busValuation: false,
      featured: false,
      relocatable: false,
      homebased: false,
      realestateincluded: false,
      reasonforsale: "free up time to pursue another very lucrativne business opportunity",
      description:
        ["BUSINESS DESCRIPTION",
          "Opportunity to purchase a profitable, well established fast food business in a high traffic strip shopping center in Arlington, TX. Casual, family-run operation offering a variety of American, Cajun & Creole dishes including po boys, fried fish & pasta plus boba tea drinks.",
          "See menu at this link:",
          "https://zingmyorder.com/restaurants/frieddaze-arlington",
          "Very clean, highly visible location. Excellent food. Uses the Clover point of sale system. Kitchen equipped to prepare any type of food. The only additional labor is a part time employee that works open to close on Friday & Saturday for $100/day plus tips. Profitable operation but it is recommended that buyer monitor the cash register for a week or two to prove additional cash sales.",
          "EXTREMELY MOTIVATED SELLER!! Seller has a lucrative opportunity to pursue and must make time to do so. MAKE ALL REASONABLE OFFERS!!",
          "Employees and customers unaware of seller’s intentions. Business to be seen as a customer only and please act like one. DO NOT CONTACT EMPLOYEES OR OWNER!! Any closer looks at the business or meetings with the owner are to be done after hours by appointment only through the broker who has all of the necessary information. For further info contact owner’s representative Stocker Travis at 214-233-6481 or email to stocker@dbi-businessadvisors.com.",
          "FACILITIES",
          "Business operates out of a 1,423 square foot facility containing a total of 32 seats.  The total rent including all triple net charges is $4,551.29. Fully equipped to sell just about any type of food.",
          "COMPETITION",
          "The trade area around the restaurant has a large concentration of food establishments and competition due to the dense population and heavy automobile traffic",
          "GROWTH AND EXPENSION",
          "There is opportunity for sales growth by adding more dishes to the menu.",
          "SUPPORT AND TRAINING",
          "Seller will provide on-site training after the purchase, transfer of supplier relationships, and provide on-call telephone assistance after completion of the training and sign a no competition agreement",
      ],
      extras: [
        ""
      ],
      images: [
        {
          fields: {
            file: {
              url: business301
            }
          }
        },
        {
          fields: {
            file: {
              url: business301a
            }
          }
        },
        {
          fields: {
            file: {
              url: nopx
            }
          }
        },
        {
          fields: {
            file: {
              url: nopx
            }
          }
        }
      ]
    }
  },
 
 /*  {
    sys: {
      id: "42"
    },
    fields: {
      name: "dry cleaners",
      slug: "dry-cleaners",
      type: "dry-cleaners",
      status: "",
      active: "",
      sold: "",
      otr: "",
      onhold: "",
      offmarket: 1,
      price: 645000,
      adlink: "",
      // https://www.bizquest.com/business-for-sale/dry-cleaning-plant-w-3-drop-off-pick-up-stations/BW2037720/
      ndalink: "",
      grosssales: 983748,
      cashflow: 108832,
      inventoryatcost: 2500,
      mthlybuildingrent: 5000,
      typeoffinancial: "Seller Financial Statements",
      reportperiodoffinancials: 3,
      ffe: 250000,
      size: 2906,
      established: 20,
      eqValuation: false,
      busValuation: true,
      featured: false,
      relocatable: false,
      homebased: false,
      realestateincluded: false,
      reasonforsale: "retirement",
      description:
        ["Incredible opportunity to purchase well established, profitable dry cleaning business. The operation includes a fully operational plant and 3 drop off/pick up stores. All stores are located in high traffic well maintained strip centers with other successful retail & dining establishments in densely concentrated residential neighborhoods in the high growth areas around the Alliance Airport. Same owner for over 30 years with long term, dedicated, well trained employees. Business is well known for excellent quality, exceptional service and has a loyal, repeat customer base. Not operated as a “discount” operation and rarely accepts coupons.",
      "For qualified parties, the real property where the plant is located is also for sale in addition to the asking price. Please inquire with broker.",
      "Seller has been involved in the dry cleaning business industry for over 35 years and is ready to begin semi-retirement and do something else a bit less demanding of his time. PRICED TO SELL QUICKLY!! MAKE ALL REASONABLE OFFERS! Financing has already been arranged with 3rd party lenders and is available to qualified applicants. "
      ],
      extras: [
        ""
      ],
      images: [
        {
          fields: {
            file: {
              url: img7
            }
          }
        },
        {
          fields: {
            file: {
              url: nopx
            }
          }
        },
        {
          fields: {
            file: {
              url: nopx
            }
          }
        },
        {
          fields: {
            file: {
              url: nopx
            }
          }
        }
      ]
    }
  }, */
   {
    sys: {
      id: "23"
    },
    fields: {
      name: "Two - High Volume, Fast Food Restaurants w/ Alcohol",
      slug: "restaurant",
      type: "restaurant",
      status: "",
      active: "1",
      sold: "",
      otr: "",
      onhold: "",
      price: 3500000,
      adlink: "https://www.dbi-businessadvisors.com",
      adlinklabel: `Privately Listed - Contact Broker`,
      ndalink: "https://www.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=b7a18029-a575-48f6-83f1-eb60d430bdcf&env=na1",
      ndalinklabel: "NDA Link:",
      grosssales: 6299158,
      cashflow: "1100000",
      inventoryatcost: "50000",
      mthlybuildingrent: 0,
      typeoffinancial: "Seller Financial Statements",
      reportperiodoffinancials: 3,
      ffe: 120000,
      size: "6322" ,
      established: 10,
      eqValuation: false,
      busValuation: false,
      featured: false,
      relocatable: false,
      homebased: false,
      realestateincluded: false,
      reasonforsale: "Other Expansion",
      description:
        ["BUSINESS DESCRIPTION", 
         "Opportunity to purchase a package of 2 extremely profitable, fast food restaurants each in rural towns 30 miles southeast of Dallas (individual locations to be provided upon receipt of signed Non-Disclosure Agreement). Annual sales continue to trend up even after the end of the pandemic. Very unique concept with limited competition. High food quality & consistency.",
        "Approximately 35% of the revenue is pick up & drive through. Primary source of revenue is food sales with alcohol sales at nearly 8 - 10% of total sales.",
      "SALE OF BUSINESS EXTREMELY CONFIDENTIAL!! Employees and customers unaware of seller's intentions. Business to be seen as a customer only and please act like one. DO NOT CONTACT EMPLOYEES OR OWNER!! Any requests for a closer look at the business or meetings with the owner are to be done after hours, by appointment only through the broker, who has all necessary information. For further information contact owner's representative, Stocker Travis at 214-233-6481 or email stocker@dbi-businessadvisors.com",
    "FACILITIES",
  "Both of these fully equipped fast food restaurants are capable of serving high volumes. One restaurant is 3,262 square feet and the other is 3,060 square feet with each paying a base rent of $9,833.61 per month plus additional rent of $1,100 per month for real estate taxes and building insurance for a total monthly gross rent of $10,933.61 per restaurant. Both premises leases expire on September 1, 2029 and each contains a 10 year renewal option.",
"COMPETITION",
"Not much serious competition as the chicken fast food franchises typically prefer more populated urban locations. Very large trade area. Customers of these restaurants are typically destination oriented and have been 'regulars' for a very long time.",
"GROWTH AND EXPANSION",
"Adding seafood items to the menu would boost sales even further.",
"SUPPORT AND TRAINING",
"Seller will provide some on-site training after the purchase, assist in securing retention of employees and transfer of supplier relationships, provide on-call telephone assistance after completion of the training and sign a non competiton agreement.",
"REASON FOR SELLING",
"The owner needs to free up time and capital to focus on two other startup fast food opportunities."],

      extras: [
        ""
      ],
      images: [
        {
          fields: {
            file: {
              url: business23
            }
          }
        },
        {
          fields: {
            file: {
              url: nopx
            }
          }
        },
        {
          fields: {
            file: {
              url: nopx
            }
          }
        },
        {
          fields: {
            file: {
              url: nopx
            }
          }
        }
      ]
    }
  }, 
  /* {
  sys: {
    id: "19"
  },
  fields: {
    name: "Steer Roping Dummy Manufacturer",
    slug: "smallmanufacturer",
    type: "other",
    status: "",
    active: "",
    sold: "1",
    otr: "",
    onhold: "",
    price: 150000,
    adlink: "",
    adlinklabel: `BizBuySell, BizQuest, LoopNet`,
    ndalink: "",
    grosssales: 175634,
    cashflow: 46769,
    inventoryatcost: 15000,
    mthlybuildingrent: 0,
    typeoffinancial: "Seller Financial Statements",
    reportperiodoffinancials: 3,
    ffe: 30000,
    size: 0,
    established: 15,
    eqValuation: true,
    busValuation: false,
    featured: false,
    relocatable: true,
    homebased: true,
    realestateincluded: false,
    reasonforsale: "Family priorities",
    description:
      ["Home Based Business", 
       "This business sells cow roping dummies for steer roping practice. Opportunity to purchase a profitable, home based, manufacturing operation that produces cow roping dummies for steer roping practice. Company has relationships with distribution channels that sell directly to consumers through their own websites, and as affiliates using Amazon.  Current channels include farm & ranch supply stores, western apparel stores, veterinary supply stores, rodeo & roping supply stores, feed & supply stores, 1 large agriculture cooperative and other assorted retail stores. Distribution contracts are flexible and do not include restrictive covenants.  Extreme potential for a buyer to incentivize distributors with allocations and an on-line ordering process.  The margins are sufficient enough for a buyer to realize significant profits (the cost to produce the product is approx. $70 with a wholesale price of approx. $210 and a retail on-line price of approx. $300).  Approx. 25% of cost of goods is in the steer head which could be further reduced by $10 each using a spin mold process (adding even more to the bottom line). There is no real competitor that proves a challenge in this niche market.. August to February is typically the peak production time. A trademark for the product is also included in the sale.",
      "Sale includes a 2016 Dodge Ram 3500 dual crew cab pickup truck, 2 trailers, a drill press & other miscellaneous manufacturing items. Easy accounting with 90% of the sales receipts are electronic transfers with the remainder being checks. Purchaser will need to decide to keep a salesman that is a manufacturer’s rep of sorts who receives a 10% commission on all products sold.",
      "Seller will provide on-site training after the purchase, assist in securing retention of the employee and transfer of supplier relationships, provide on-call telephone assistance after completion of the training and sign a non-competition agreement."],
    
    extras: [
      ""
    ],
    images: [
      {
        fields: {
          file: {
            url: business21
          }
        }
      }, 
      {
        fields: {
          file: {
            url: nopx
          }
        }
      },
      {
        fields: {
          file: {
            url: nopx
          }
        }
      },
      {
        fields: {
          file: {
            url: nopx
          }
        }
      }
    ]
  }
},*/

  /* {
      sys: {
      id: "1"
    },
    fields: {
      name: "Aluminum Casting",
      slug: "metal fabrication",
      type: "manufacturing",
      status: "",
      active: "",
      sold: "",
      otr: 1,
      onhold: "",
      price: 395000,
      adlink: "",
      ndalink: "",
      grosssales: 948592,
      cashflow: 0,
      inventoryatcost: 40000,
      mthlybuildingrent: 5357,
      typeoffinancial: "Seller Financial Statements",
      reportperiodoffinancials: 3,
      FFE: 360000,
      size: 12300,
      established: 50,
      eqValuation: false,
      busValuation: true,
      featured: false,
      relocatable: false,
      homebased: false,
      realestateincluded: false,
      reasonforsale: "retirement",
      description:
        ["Business Description", 
         "Primary business model is selling aluminum castings, aluminum fabrication services and the buying and selling of aluminum hardware for the ornamental/architectural/decorative industry. It is a green sand mold aluminum foundry with includes 4 squeezer molding machines, 1 semi-automatic match plate machine and a floor-mold area for larger castings. The operation has been at the current location since 1965 and has gone through major plant/equipment upgrades and expansions in 1978, 1985 & 1995. Very strong, repetitive client base.", 
         "Nationwide marketing reach with some international business which includes Canada, Puerto Rico, the West Indies and Barbados. Most domestic selling activity is mainly the southeast US (Florida) and the east coast up through New Jersey. The current marketing is via a hard copy catalog an Auto-Cad library disc, a professional web site, advertising in trade magazines and word of mouth from the existing customers. The time tested production process and the order processing in place allow for a smoothly operating business.",
         "The primary sources of revenue are aluminum castings at approximately 93% with the remaining being tooling & setup, aluminum hardware and fabrication/misc. services. Perfect time to buy the operation as the peak season that generates approximately 85% of the annual sales is between March and November. Market outlook excellent as single-family home construction and commercial construction continues to remain strong. The Company’s competitive edge is that all products are American made and its ability to perform pattern work with the availability of an in-house sub-contracted woodcarver. Competition with this business and startup in the industry would be most difficult with the amount and cost of the processing equipment required, the time, cost & expertise to produce over 3000 designs currently on hand and the its ability to do custom jobs. Huge upside to expand the current operation by adding shifts as it is currently operating at approximately 60% of its current capacity in only 1 shift. Other upside opportunities include introducing an import line, commercial castings, 3D printing, more focus on internet related products/upgrading the website, automation of the cleaning steps and the purchase of an equipment lathe & milling machine for tooling and marine grade aluminum castings. Extremely motivated seller who has been at this same business in this same location since 1982. He is turning 82 years old this year and is ready to retire in order to be able to travel with his wife.", 
         "The asking price includes all furniture, fixtures, equipment appraised at $360,000 and ALL OF THE OWNED PATTERNS CONSTRUCTED AND COLLECTED OVER 75+ YEARS IN BUSINESS!! Not much in the way of goodwill due to the lack of any substantial cash flow but the upside to launch the operation to the next level is real. With moderate working capital and capital expenditure investment, the capacity of the business can easily be doubled by adding another shift. All of the equipment needed to produce the products is in place and in use with a dependable labor crew most of whom have been with the operation for 10 or more years. Very solid, repeat client base with a website and catalog already done and ready to launch it to the next level.","The real estate is also available at an asking price of $700,000 which includes 2.5 acres or 108,900 square feet with improvements as follows: 3 roofed metal building structures and a roofed concrete block building totaling 12,300 square feet plus additional floor space comprised of 3 roof only structures totaling 3,300 square feet with mezzanines and a second floor comprising of 2,725 square feet (site plan available for specifics upon request.)",
         "Business hours Monday - Friday 8:00 am – 5:00 pm",
         "Seller will provide on-site training after the purchase, assist in securing retention of employees and transfer of supplier relationships, provide on-call telephone assistance after completion of the training and sign a no competition agreement.",
         "The information contained in this marketing is subject to change, corrections, or withdrawal without notice. Information provided herein has been provided by the Seller and not by Broker.",
          "The Seller has listed this business strictly as an asset transaction. This is not an offer to sell securities. This is not a solicitation to sell securities."],
      extras: [
        ""
      ],
      images: [
        {
          fields: {
            file: {
              url: img13
            }
          }
        },
        {
          fields: {
            file: {
              url: img13A
            }
          }
        },
        {
          fields: {
            file: {
              url: img13B
            }
          }
        },
        {
          fields: {
            file: {
              url: nopx
            }
          }
        }
      ]
    }
  }, */
 
 
  /* {
    sys: {
      id: "4"
    },
    fields: {
      name: "virtual reality arcade",
      slug: "entertainment",
      type: "entertainment",
      status: "",
      active: "",
      sold: "",
      otr: "",
      onhold: "",
      offmarket: "1",
      price: 20000,
      adlink: "",
      ndalink: "",
      grosssales: 40825,
      cashflow: "",
      inventoryatcost: "",
      mthlybuildingrent: 3794.85,
      typeoffinancial: "Seller Tax Statements",
      reportperiodoffinancials: 2.5,
      FFE: 10000,
      size: 884,
      established: 2.5,
      eqValuation: false,
      busValuation: false,
      featured: false,
      relocatable: false,
      homebased: false,
      realestateincluded: false,
      reasonforsale: "semi-absentee owners desire to divest to focus on existing careers",
      description:
        ["BUSINESS SUMMARY & DESCRIPTION",
          "Opportunity to purchase established virtual reality arcade in a prime location in a major shopping center in Frisco, TX at the southwest corner of Lebanon Road and the North Dallas Tollway. Front door of the business is in direct view of a high volume Chick Fil - A double drive through window which is great free advertising. All state of the art equipment designed to provide kids and adults the highest quality virtual reality gaming experience in a family friendly atmosphere. Professionally run operation without the annoying loud noises associated with most arcades. Players experience the virtual reality in individual stations wearing headphones while family members or friends can relax in the lounge area. High definition monitors are placed through the space that show each individual player exactly what they are experiencing. Have an inventory of over 90 games of which 15 are multiplayer.",
          "Extremely profitable operation with rent being the only major overhead item. Both owners have other full time jobs and originally set the business up to be run semi-absentee. It was opened at the time Covid just got on the map back in the early part of 2019 and has never really had a chance to grow. The virtual gaming reality industry is extremely popular in Europe and is slowly making its way to the states.",
          "Prices per station are $44.99 for one hour and $24.99 for ½ hour. Can be booked for date nights, events, parties, and team building corporate events. Seller will provide on-site training after the purchase, provide on-call telephone assistance after completion of the training and sign a no competition agreement.",
          "VERY MOTIVATED SELLER!! Will look at any reasonable offers at this point. The owners started the business with the intention of operating it absentee as it is such a low overhead operation but like everyone else did not predict the long term effect of the Covid pandemic which just took out the corporate event piece of the business. All slowly coming back but both owners have responsibilities with full time jobs and families and need to divest of this business as soon as possible. Perfect time and opportunity for a marketing minded individual to pick this business up and take it to the next level.",
          "FACILITY:  Size: 884 square feet  /  Security Deposit: $4,038  /  Lease expiration: 10/31/2024",                       
          "Base monthly rental: $2,789.77",
          "Additional rent: $996.08(tenant pro-rated share of common area maintenance, building insurance & real estate taxes all of which are adjusted annually)",
          "Total monthly rent: $3,794.85",
          "OPERATIONAL: License / permit requirements: sales tax, certificate of occupancY",
          "Employees: None(owner operated)",
          "Average monthly payroll(non - owner): N/A",
          "Owner involvement: 2 owners each of whom work part time in the business",
          "Days open: Tuesday - Sunday  Hours open: 2: 00 p.m. – 8: 00 p.m.",
          "Business may be seen anytime as a customer only and please act like one. Please do not discuss the fact that the business is for sale with any customers. Any closer looks at the business or discussions with the owner are to be done after hours by appointment only through the broker who has all of the necessary information. For further info contact owners representative Stocker Travis at 214-233-6481 or email to stocker@dbi-businessadvisors.com.",
          "BUSINESS NAME: XS VR Let's Play",
          "https://www.facebook.com/XSVR.Frisco/videos/1397664313927617/",
          "OWNER:  Devlina Ghosh",
          "ADDRESS:  5355 Dallas Parkway #623",
          "CITY/STATE/ZIP:  Frisco, TX 75034",
          "WEBSITE:  www.xs-vr.com",
          ],
      extras: [
        ""
      ],
      images: [
        {
          fields: {
            file: {
              url: business19
            }
          }
        },
        {
          fields: {
            file: {
              url: nopx
            }
          }
        },
        {
          fields: {
            file: {
              url: nopx
            }
          }
        },
        {
          fields: {
            file: {
              url: nopx
            }
          }
        }
      ]
    }
  }, */
  {
    sys: {
      id: "5"
    },
    fields: {
      name: "mail and shipping",
      slug: "mail-shipping",
      type: "retail",
      status: "",
      active: "",
      sold: 1,
      otr: "",
      onhold: "",
      offmarket: "",
      price: 45000,
      adlink: "",
      adlinklabel: `BizBuySell, BizQuest, LoopNet`,
      ndalink: "",
      grosssales: 137200,
      cashflow: 12900,
      inventoryatcost: 3500,
      mthlybuildingrent: 2000,
      typeoffinancial: "Seller Financial Statements",
      reportperiodoffinancials: 3,
      ffe: 15000,
      size: 1125,
      established: 3,
      eqValuation: false,
      busValuation: false,
      featured: false,
      relocatable: false,
      homebased: false,
      realestateincluded: false,
      reasonforsale: "retirement",
      description:
        ["BUSINESS SUMMARY & DESCRIPTION - Established, profitable mail center located in a well-traveled shopping center in north Dallas close to Valley View Lane & Josey Lane in Farmers Branch, TX.  Established, repeat clientele.  Great demographic for this type of business.  Fairly easy to operate(short hours, limited equipment, inventory & labor).  Business currently run full time by the owner with no employees.  In 2007 the business was originally located in the Tom Thumb center at the southeast corner of Spring Valley Rd. & Marsh Lane and was moved to the current location in January / 2013. Extreme upside to expand the leasehold into space available next door and increase sales with on - site, full- service printing services as well as fulfillment and bulk mailing services all of which the current owner does not offer to the existing customer base.",
          "Sales revenues include worldwide tracking & shipping. Shippers include Federal Express(FedEx), United Parcel Service(UPS), DHL, US Postal Service(USPS), Lone Star Overnight(LSO) and International Package Shipping(IPS). The business offers a wide variety of services including custom packaging services, private mailbox rentals, various services (copies, fax sending & receiving, mail processing services, notary public) and retail products (stamps, cardboard boxes, office products / supplies, greeting cards, mailing / packing / moving supplies, maps, rubber stamps, business cards, unique gift & novelty items).",
          "Business also offers computer repair services, video & film conversion and purchases used calculators, clickers and text books all through outside third parties. Mailbox rental very profitable revenue source. There are a total of 210 small, medium and large mailboxes available of which approx. 32 % are currently rented out. Business also offers digital mailbox services to customers which is extremely useful to business owners who desire to receive as well as forward mail, scan, shred or fax documents, deposit checks, route packages and have all such activities communicated to them by email.",
          "PRICED TO SELL QUICKLY BEFORE HOLIDAY RUSH!!  Extremely motivated situation! Seller at retirement age and will consider all reasonable offers!!", 
          "Business may be shown at any time during normal business hours. Not a confidential sale but owner has requested to keep the conversation to a minimum when in front of customers.",
          "Business Hours - Monday - Friday 9: 00 am – 6: 00 pm; Sat 9: 00 am – 1: 00 pm", 
          "The information contained in this marketing is subject to change, corrections, or withdrawal without notice. Information provided herein has been provided by the Seller and not by Broker. The Seller has listed this business strictly as an asset transaction.",
          "This is not an offer to sell securities. This is not a solicitation to sell securities. Listing Business Broker Firm: Diversified Business Investments. Contact Stocker Travis, Principal at 214 - 725 - 2775 or Stocker@dbi-businessadvisors.com ", 
          "FACILITIES – Monthly Bldg Rent $1, 987.21; Approximately 1, 125 sq.ft.; Lease expires October 31, 2020.  ", 
          "MARKET OUTLOOK & COMPETITION - The COVID pandemic has affected the shipping business in a positive way.Sales are up 26 % over last year through September / 2020. Sales revenue will easily top $170K in 2020 over $117K in 2019. Virtually no competition in the immediate area.",
          "GROWTH POTENTIAL & EXPANSION - Suggest buyer joins Retail Shipping Associates as a premium member to gain access to a variety of services and products to add including passport & identity photos, passport expediting, large format printing(banners, signs, blueprints, etc.), larger selection of greeting cards & other retail items, delivery services, additional digital or virtual mailbox services and become a Package Business Center.SUPPORT & TRAINING - Seller will train at no cost to buyer for one to two weeks as needed.",
          "REASONS FOR SALE - Health issues / Retirement  ",
          "Contact Email: stocker@dbi-businessadvisors.com"],
      extras: [
        ""
      ],
      images: [
        {
          fields: {
            file: {
              url: img5
            }
          }
        },
        {
          fields: {
            file: {
              url: businessepos1
            }
          }
        },
        {
          fields: {
            file: {
              url: businessepos2
            }
          }
        },
        {
          fields: {
            file: {
              url: businessepos3
            }
          }
        }
      ]
    }
  },
  
  {
    sys: {
      id: "7"
    },
    fields: {
      name: "Pool Service",
      slug: "poolservices",
      type: "service",
      status: "",
      active: "",
      sold: 1,
      otr: "",
      onhold: "",
      offmarket: "",
      price: 350000,
      adlink: "",
      adlinklabel: `BizBuySell, BizQuest, LoopNet`,
      ndalink: "",
      grosssales: 252700,
      cashflow: 47200,
      inventoryatcost: 10000,
      mthlybuildingrent: 4500,
      typeoffinancial: "Seller Financial Statements",
      reportperiodoffinancials: 3,
      ffe: 27500,
      size: 875,
      established: 5,
      eqValuation: false,
      busValuation: false,
      featured: false,
      relocatable: false,
      homebased: false,
      realestateincluded: false,
      reasonforsale: "retirement",
      description: [
        "Profitable Pool Repair and Consulting Business sold."
      ],
      extras: [
        
      ],
      images: [
        {
          fields: {
            file: {
              url: business18
            }
          }
        },
        {
          fields: {
            file: {
              url: nopx
            }
          }
        },
        {
          fields: {
            file: {
              url: nopx
            }
          }
        },
        {
          fields: {
            file: {
              url: nopx
            }
          }
        }
      ]
    }
  },
  
  {
    sys: {
      id: "9"
    },
    fields: {
      name: "Fireplaces & BBQ",
      slug: "fireplaces",
      type: "service",
      status: "",
      active: "",
      sold: 1,
      otr: "",
      onhold: "",
      offmarket: "",
      price: 350000,
      adlink: "",
      adlinklabel: `BizBuySell, BizQuest, LoopNet`,
      ndalink: "",
      grosssales: 525000,
      cashflow: 350000,
      inventoryatcost: 10000,
      mthlybuildingrent: 4500,
      typeoffinancial: "Seller Financial Statements",
      reportperiodoffinancials: 3,
      ffe: 27500,
      size: 875,
      established: 3,
      eqValuation: false,
      busValuation: false,
      featured: false,
      relocatable: false,
      homebased: false,
      realestateincluded: false,
      reasonforsale: "retirement",
      description: [
        "BUSINESS DESCRIPTION",
            ],
      extras: [
        "Inventory included in asking price",
        "Years established 2",
        "Hours Mon - Sat 11:00 am - 9:00 pm   Sunday 12 noon - 6:00 pm",
      ],
      images: [
        {
          fields: {
            file: {
              url: business14
            }
          }
        },
        {
          fields: {
            file: {
              url: nopx
            }
          }
        },
        {
          fields: {
            file: {
              url: nopx
            }
          }
        },
        {
          fields: {
            file: {
              url: nopx
            }
          }
        }
      ]
    }
  },
  {
    sys: {
      id: "10"
    },
    fields: {
      name: "Florist",
      slug: "florist",
      type: "florist",
      status: "",
      active: "",
      sold: 1,
      otr: "",
      onhold: "",
      offmarket: "",
      price: 250000,
      adlink: "",
      adlinklabel: `BizBuySell, BizQuest, LoopNet`,
      ndalink: "",
      grosssales: 375783,
      cashflow: 110050,
      inventoryatcost: 10000,
      mthlybuildingrent: 1655,
      typeoffinancial: "Seller Financial Statements",
      reportperiodoffinancials: 3,
      ffe: 20000,
      size: 1440,
      established: 24,
      eqValuation: false,
      busValuation: false,
      featured: false,
      relocatable: false,
      homebased: false,
      realestateincluded: false,
      reasonforsale: "retirement",
      description: ["Excellent opportunity to purchase established, upscale florist in the perfect location in a bustling business district of Addison, TX.Very clean, attractive operation. Perfect for a family business. Was originally started & located in the Villages on the Parkway at the southeast corner of Belt Line Rd. & the north Dallas tollway and then moved to a strip center at the southeast corner of Montfort Drive & Belt Line before moving to the current location in February of 2014.",
"Revenue sources include fresh flowers, dried flowers, European garden baskets and miscellaneous gift items. Wire services include Teleflora & Floral Source.Approximately 1 / 3 of the annual revenues takes place during Valentine’s Day, Mother’s Day & Christmas. The average ticket price is approx.$150.",
"The operation has always been profitable.Has always relied on location, word of mouth and the 37 + year old name to attract customers.With the exception of Yelp, the current owner does little to no advertising with the majority of the business coming over the internet with very little walk in.With the proper management and promotion, a marketing minded individual could use this operation as a launching pad to take it up to the next level. Utilizing low cost, effective promotions plus pushing a presence using social media should increase revenues and earnings significantly.The social media piece would not be difficult to kick in as the majority of on - line reviews have always been positive.",
"Extremely motivated situation.The current owners have always enjoyed the business and have always made money but are ready for a change.They both work in the busines and want to sell and retire comfortably while they are still in good health. PLEASE SUBMIT ALL REASONABLE OFFERS!! Seller financing possibly available to the right party with sufficient credit, direct industry experience and cash down payment.",
"Seller will provide on - site training after the purchase, assist in securing retention of employees and transfer of supplier relationships, provide on - call telephone assistance after completion of the training and sign a no competition agreement.",
"SALE OF BUSINESS EXTREMELY CONFIDENTIAL!! Employees and customers unaware of seller’s intentions.Business to be seen as a customer only and please act like one. ",
"DO NOT CONTACT EMPLOYEES OR OWNER!! Any closer looks at the business or meetings with the owner are to be done after hours by appointment only through the broker who has all of the necessary information.For further info contact owner’s representative Stocker Travis at 214 - 725 - 2775 or email to Stocker @dbi-businessadvisors.com"

      ],
      extras: [
        "Inventory included in asking price",
        "Years established 2",
        "Hours Mon - Sat 11:00 am - 9:00 pm   Sunday 12 noon - 6:00 pm",
      ],
      images: [
        {
          fields: {
            file: {
              url: business15
            }
          }
        },
        {
          fields: {
            file: {
              url: business15A
            }
          }
        },
        {
          fields: {
            file: {
              url: business15B
            }
          }
        },
        {
          fields: {
            file: {
              url: nopx
            }
          }
        }
      ]
    }
  },
  {
    sys: {
      id: "11"
    },
    fields: {
      name: "Midas Franchise",
      slug: "auto",
      type: "auto repair",
      status: "",
      active: "",
      sold: 1,
      otr: "",
      onhold: "",
      offmarket: "",
      price: 150000,
      adlink: "",
      adlinklabel: `BizBuySell, BizQuest, LoopNet`,
      ndalink: "",
      grosssales: 252700,
      cashflow: 47200,
      inventoryatcost: 10000,
      mthlybuildingrent: 4500,
      typeoffinancial: "Seller Financial Statements",
      reportperiodoffinancials: 3,
      ffe: 27500,
      size: 875,
      established: 5,
      eqValuation: false,
      busValuation: false,
      featured: false,
      relocatable: false,
      homebased: false,
      realestateincluded: false,
      reasonforsale: "downsizing",
      description: [
        "BUSINESS SUMMARY & DESCRIPTION ",
        "Opportunity to purchase established, profitable Midas auto repair facility in a thriving business and residential community in the Fort Worth, Texas.The shop is in a 4, 085 square foot free standing building with 6 bays & 6 lifts.Located in a neighbohood being revitalized next to a new City Hall built approx. 4 years ago with new retail and office space planned for the property next door.Very attractive facility(was re- painted last year and has an excellent “curb” appeal).",
"The Midas name is over 50 years old and is a globally recognized brand as one of the world’s largest providers of automotive services.The company has over 2, 300 franchised, licensed and company owned stores in 15 countries and offers a wide range of maintenance and repair services including brake, exhaust, maintenance, tires, steering and suspension services.Industry leader in exhausts, brakes and shocks / struts and has strategic alliances for parts distribution in north America as well as attractive retail programs in tires and fleet account management.The R.O.Writer shop management and point of sale system is state of the art and is constantly evolving and improving based on customer & franchisee needs.Midas has a consistent track record of adapting and adjusting to new consumer demands with new programs and retail services as well as supporting a strict adherence to services standards for the auto repair business.",
"As a Midas franchisee the buyer will receive on - going orientation and training programs as well as extensive marketing and operations support.Proven support & resource systems in place from customer service training to shop management systems which include a dedicated franchisee internet site that provides access to reports,  bulletins, shop and account data as well as shop training support through the Midas Learning System.",
"Seller will also provide on - site training after the purchase, assist in securing retention of employees and transfer of supplier relationships, provide on - call telephone assistance after completion of the training and sign a no competition agreement.Buyer must be approved by the franchiser prior to the sale and is required to complete a 3 - week extensive training period at the franchise Palm Beach Gardens, Florida headquarters.The sale will entail the negotiaton of a new 20 year franchise agreement with the Midas franchisee.The typical franchise royalty is 10 % of the gross sales revenues except for tire sales which are 6 % and battery sales which are 2 %.",
"Extremely motivated situation.Priced right to sell quickly.The seller and his family have been a Midas franchisee for 40 years and desires to downsize to focus on other real estate and business interests..The geographical distance from his home and other operations is also an issue.Willing to look at all reasonable offers at this point.",
"Bank financing available.",
"SALE OF BUSINESS EXTREMELY CONFIDENTIAL!! Employees and customers unaware of seller’s intentions.Business to be seen as a customer only and please act like one.DO NOT CONTACT EMPLOYEES OR OWNER!! Any closer looks at the business or meetings with the owner are to be done after hours by appointment only through the broker who has all of the necessary information.For further info contact owner’s representative Stocker Travis at 214 - 725 - 2775 or email at Stocker @dbi-businessadvisors.com."
       
      ],
      extras: [
        
      ],
      images: [
        {
          fields: {
            file: {
              url: img11
            }
          }
        },
        {
          fields: {
            file: {
              url: nopx
            }
          }
        },
        {
          fields: {
            file: {
              url: nopx
            }
          }
        },
        {
          fields: {
            file: {
              url: nopx
            }
          }
        }
      ]
    }
  },
  
  /* {
    sys: {
      id: "7"
    },
    fields: {
      name: "franchise",
      slug: "franchise-childcare",
      type: "franchise-childcare",
      price: 300000,
      adlink: 65000,
      grosssales: 40000,
      cashflow: 50000,
      inventoryatcost: 4000,
      mthlybuildingrent: 5200,
      typeoffinancial: "Seller Financial Statements",
      reportperiodoffinancials: 3,
      FFE: 24000,
      size: 1990,
      established: 5,
      eqValuation: false,
      busValuation: false,
      featured: false,
      relocatable: false,
      homebased: false,
      realestateincluded: false,
      reasonforsale: "partnership / dissolution",
      description:
        ["Not available yet"],
      extras: [
        ""
      ],
      images: [
        {
          fields: {
            file: {
              url: img7
            }
          }
        },
        {
          fields: {
            file: {
              url: business2
            }
          }
        },
        {
          fields: {
            file: {
              url: business3
            }
          }
        },
        {
          fields: {
            file: {
              url: business4
            }
          }
        }
      ]
    }
  } */
 /* {
    sys: {
      id: "32"
    },
    fields: {
      name: "food & beverage",
      slug: "food-beverage",
      type: "food & beverage",
      status: "",
      active: "",
      sold: "",
      otr: "",
      onhold: "",
      price: 250000,
      adlink: "",
      ndalink: "",
      grosssales: 400000,
      cashflow: 67000,
      inventoryatcost: 4000,
      mthlybuildingrent: 5000,
      typeoffinancial: "Seller Financial Statements",
      reportperiodoffinancials: 3000,
      FFE: 45000,
      size: 3990,
      established: 5,
      eqValuation: false,
      busValuation: false,
      featured: false,
      relocatable: false,
      homebased: false,
      realestateincluded: false,
      reasonforsale: "partnership dissolution",
      description: ["No details yet."],
      extras: [
        ""
      ],
      images: [
        {
          fields: {
            file: {
              url: img3
            }
          }
        },
        {
          fields: {
            file: {
              url: businessrest1
            }
          }
        },
        {
          fields: {
            file: {
              url: businessrest2
            }
          }
        },
        {
          fields: {
            file: {
              url: businessrest3
            }
          }
        }
      ]
    }
  },*/
  {
    sys: {
      id: "19"
    },
    fields: {
      name: "Nail Salon",
      slug: "nail salon",
      type: "retail",
      status: "",
      active: "",
      sold: "",
      otr: "",
      onhold: "",
      offmarket: "1",
      price: 35000,
      adlink: "",
      adlinklabel: `BizBuySell, BizQuest, LoopNet`,
      ndalink: "",
      grosssales: 50000,
      cashflow: 10655,
      inventoryatcost: 6500,
      mthlybuildingrent: 3740,
      typeoffinancial: "Seller Financial Statements",
      reportperiodoffinancials: 3,
      ffe: 30000,
      size: 1050,
      established: 25,
      eqValuation: false,
      busValuation: false,
      featured: false,
      relocatable: false,
      homebased: false,
      realestateincluded: false,
      reasonforsale: "Spread too thin",
      description:
        ["BUSINESS DESCRIPTION", 
         "Opportunity to purchase brand new, upscale, well stocked nail salon in a busy, high traffic shopping center in an affluent area in far north Dallas. Very upscale clients who tip the shop employees extremely well. Very attractive & bright well laid out operation with a beautiful reception area & a nice bar area that offers complimentary drinks. Has 8 pedicure stations, 5 manicure stations, facial waxing station, break room & laundry room & an A.D.A. compliant bathroom.", 
         "One of the original nail salons in Frisco opened over 25 years ago (was originally called Frisco Nails). Seller is the 3rd owner in that time to do extensive remodeling. Business closed for remodeling from March/2022 until May/2022. Brand new items include interior leasehold improvements and equipment (washer, dryer, refrigerator & ice maker) plus a towel warmer & hot stone warmer. The landlord has committed $12,000 for a new HVAC system when needed in the future.",
         "Current owner inherited a shop left in disrepair by the previous owner and with a terrible reputation and average services. New owner has cleaned up the shop (literally) as well as erased it’s poor reputation. The business is primed for a dedicated owner/operator buyer with the talent & drive to take it to the next level without substantial startup costs & working capital reserves necessary to open & start a new shop from nothing which would easily total more than $85,000. This shop is only open 5 days per week. There is an opportunity to increase revenues by expanding the hours and open 6-7 days per week as is the case with most nail salons.", 
         "EXTREMELY MOTIVATED SELLER!! PRICED TO SELL QUICKLY!! The owner is committed to other full time business endeavors and just does not have the time necessary to manage and maximize this potential.",
         "Seller will provide on-site training after the purchase, provide on-call telephone assistance after completion of the training and sign a no competition agreement.",
         "SALE OF BUSINESS EXTREMELY CONFIDENTIAL!! Employees and customers unaware of seller intentions. Business to be seen as a customer only and please act like one. DO NOT CONTACT EMPLOYEES OR OWNER!! Any closer looks at the business or meetings with the owner are to be done after hours by appointment only through the broker who has all of the necessary information. For further info contact owner’s representative Stocker Travis at 214-233-6481 or email to stocker@dbi-businessadvisors.com",
         "Business Hours:  Tuesday - Saturday 10 AM - 7 PM",
         "Seller will provide on-site training after the purchase, assist in securing retention of employees, provide on-call telephone assistance after completion of the training and sign a no competition agreement.",
         "The information contained in this marketing is subject to change, corrections, or withdrawal without notice. Information provided herein has been provided by the Seller and not by Broker.",
          "The Seller has listed this business strictly as an asset transaction. This is not an offer to sell securities. This is not a solicitation to sell securities."],
      extras: [
        ""
      ],
      images: [
        {
          fields: {
            file: {
              url: business20
            }
          }
        },
        {
          fields: {
            file: {
              url: nopx
            }
          }
        },
        {
          fields: {
            file: {
              url: nopx
            }
          }
        },
        {
          fields: {
            file: {
              url: nopx
            }
          }
        }
      ]
    }
  },
  {
    sys: {
      id: "288"
    },
    fields: {
      name: "Luxury skin, face body care boutique & IV drip therapy franchise",
      slug: "retailspa",
      type: "retail-spa",
      status: "",
      active: "",
      sold: "",
      otr: "",
      onhold: "",
      offmarket: "1",
      price: 250000,
      adlink: "",
      adlinklabel: `BizBuySell, BizQuest, LoopNet`,
      ndalink: "",
      grosssales: 0,
      cashflow: 0,
      inventoryatcost: 0,
      mthlybuildingrent: 5475,
      typeoffinancial: "",
      reportperiodoffinancials: 3,
      ffe: 350000,
      size: 2100,
      established: 2,
      eqValuation: false,
      busValuation: false,
      featured: false,
      relocatable: false,
      homebased: false,
      realestateincluded: false,
      reasonforsale: "management and undercapitalized",
      description:
        ["ASSET SALE",
          "For Sale: Highly profitable and in-demand IV INFUSION Franchise DRIPBar With Plano exclusive rights & a fully equipped med spa featuring top-tier Vitamin IV Therapy. Prime opportunity in the booming health and wellness sector. Don't miss out on this investment!",
      "The Elite Med Spa & Infusion Center was designed to provide nonsurgical treatments for the skin, face and body. Perfect location and demographic for this business model. Provides a high quality product and the latest “state of the art” services giving the most effective and advanced results available today. Services include injectables (Botox), facial treatments (microdermabration, chemical & acne peels, hydrafacials, masks & collagen induction therapy) laser treatments (tattoo & hair removal) and infusion therapy (hydration, skin brightening, body cleansing & detox, allergy & immunity boosts). There is an experienced and well-versed team of aestheticians and registered nurses with the knowledge of modern methods of administering injectables and performing laser treatments all of whom are available to come back to work for a new owner. To satisfy the Texas state regulations regarding Botox injections, there is a highly experienced medical provider available who has 30 plus years of experience in the medical cosmetic industry including cosmetic surgery and laser technology.",
      "Sale also includes a highly profitable and popular DRIPBar franchise which specializes in IV drip therapy which offers some of the fastest growing health care services available. The DRIPBar concept is focused on helping people take their healthcare beyond diet, exercise, and traditional medicine to support their physical, mental, and cellular wellness. Provides the buyer who can be a semi-absentee owner with everything needed in a business opportunity in the health space. The franchise is backed by a winning business model which is easy to scale with incredible corporate support. There are a total of 103 DRIPBar locations in the country with over 600 in development in the U.S. and Canada. Can cost up to $400K to build & start up this type of business from the ground up. See https://www.thedripbar.com for more information.",
      "LOCATION",
      "Plano, TX",
      "FACILITIES",
      "Current owners invested over $700K to rehab the leasehold including new flooring, ceilings and replacement of all furniture, fixtures & equipment (was a chiropractic clinic for 15+ years). Very attractive facility perfect for this type of business. The are many businesses of this type in and around north Dallas & Plano but none coming even close to the overall eye appeal and number or rooms available for the services). Website for individual business and website for DRIPbar infusion therapy still operational with an excellent social media presence.",
      "SUPPORT & TRAINING",
      "Seller will provide on-site training after the purchase, assist in securing retention of prior employees and transfer of supplier relationships, provide on-call telephone assistance after completion of the training and sign a no competition agreement.",
      "BUSINESS WEBSITE",
      "https://www.elitemedspatx.com/"
      ],
      extras: [
        ""
      ],
      images: [
        {
          fields: {
            file: {
              url: business288
            }
          }
        },
        {
          fields: {
            file: {
              url: business288b
            }
          }
        },
        {
          fields: {
            file: {
              url: business288e
            }
          }
        },
        {
          fields: {
            file: {
              url: business288a
            }
          }
        },
        {
          fields: {
            file: {
              url: business288c
            }
          }
        },
        {
          fields: {
            file: {
              url: business288d
            }
          }
        }
      ]
    }
  },
  {
    sys: {
      id: "4"
    },
    fields: {
      name: "glass",
      slug: "glass",
      type: "glass",
      status: "",
      active: "",
      sold: "",
      otr: "",
      onhold: "",
      offmarket: "1",
      price: 995000,
      adlink: "",
      adlinklabel: `BizBuySell, BizQuest, LoopNet`,
      ndalink: "",
      grosssales: 3494712,
      cashflow: 277939,
      inventoryatcost: 100000,
      mthlybuildingrent: 5000,
      typeoffinancial: "Seller Financial Statements",
      reportperiodoffinancials: 3,
      ffe: 150000,
      size: 2688,
      established: "30",
      eqValuation:  false,
      busValuation: true,
      featured: false,
      relocatable: false,
      homebased: false,
      realestateincluded: false,
      reasonforsale: "retirement",
      description:
        ["Opportunity to purchase profitable, well established premier custom glass company serving residential and commercial customers in the Dallas/Fort Worth area. Business located in a thriving suburb north of Fort Worth, TX with a median income over $140,000 annually and is the area’s original and oldest glass company. Local business is family owned and operated. Insured, bonded and rated with the Better Business Bureau, the company provides high quality custom glass services with exceptional customer service at competitive prices.  ",
      "Sources of revenues include residential and commercial installation and sales. Residential sales include primarily showers, mirrors, windows, door glass & cabinet glass. Commercial sales include exterior and interior glass of any type. Projects include but are not limited to retail centers, medical offices, churches, and warehouses. Areas served include the entire Dallas/Fort Worth metroplex.  ",
      "Seller will provide on-site training after the purchase, assist in securing retention of employees and transfer of supplier relationships, provide on-call telephone assistance after completion of the training and sign a no competition agreement.   ",
      "EXTREMELY MOTIVATED SITUATION!! Seller has been running the business on a semi-absentee basis. Now desires to sell as soon as possible in order to move to a semi-retired status. MAKE ALL REASONABLE OFFERS!! Bank financing available to qualified applicants with sufficient cash injection and credit.",
      "THE REAL PROPERTY AND IMPROVEMENTS ARE ALSO AVAILABLE AT AN ASKING PRICE OF $750,000. Prime, high traffic corner with ample development opportunity. Broker has all details if interested. ",  
      "SALE OF BUSINESS EXTREMELY CONFIDENTIAL!! Employees and customers unaware of seller’s intentions. DO NOT CONTACT EMPLOYEES OR OWNER!!  Any closer look at the business, or meetings with the owner are to be done after hours by appointment only through the broker who has all of the necessary information. For further info contact owner’s representative Stocker Travis at 214-233-6481 or email to stocker@dbi-businessadvisors.com.  ",
      ],
      extras: [
        ""
      ],
      images: [
        {
          fields: {
            file: {
              url: img6
            }
          }
        },
        {
          fields: {
            file: {
              url: nopx
            }
          }
        },
        {
          fields: {
            file: {
              url: nopx
            }
          }
        },
        {
          fields: {
            file: {
              url: nopx
            }
          }
        }
      ]
    }
  }, 
  {
    sys: {
      id: "22"
    },
    fields: {
      name: "Ground Level Storage Container Transportation",
      slug: "transportation",
      type: "other",
      status: "",
      active: "",
      sold: "",
      otr: "",
      onhold: 1,
      price: 850000,
      adlink: "",
      adlinklabel: `BizBuySell, BizQuest, LoopNet`,
      ndalink: "",
      grosssales: 825935,
      cashflow: "",
      inventoryatcost: "",
      mthlybuildingrent: 0,
      typeoffinancial: "Seller Financial Statements",
      reportperiodoffinancials: 3,
      ffe: 450000,
      size: 0,
      established: 20,
      eqValuation: true,
      busValuation: false,
      featured: false,
      relocatable: true,
      homebased: true,
      realestateincluded: false,
      reasonforsale: "Retirement/Health",
      description:
        ["Home Based Business", 
         "Established container and trailer trucking company for sale that specializes in moving empty shipping containers and container offices in and around Texas and the surrounding states including Oklahoma, Louisiana, Arkansas & New Mexico. Use of a combination of trained drivers and special heavy-duty equipment ensure customers' long term moves are hassle free. Services include delivery, pick-up, move on site (MOS) and relocation of the containers. Multi-billion dollar industry. Not to be confused with intermodal freight operations which are an entirely different industry involving the actual shipment of the freight within the containers. This business transports the empty container for a completely different use. Types of containers include Mobile Mini, Williams/Scotsman and Mobile Modular. Typically a 3rd party buys the containers and after renting them out to constructions sites will use the services to actually transport the container to the appropriate site. Customers include Mobile Mini, Container Storage, Excalibur Containers and United Rental. Have similar customer base as those with competitor by the name of Dascke. Drivers currently with the company are more dependable than most as the owner pays them extremely well (typically around 30% of the gross revenue) and provides health insurance.",
        "Seller will provide on-site training after the purchase, assist in securing retention of employees and transfer of supplier relationships, provide on-call telephone assistance after completion of the training and sign a no competition agreement",
        "Assets include 5 heavy duty freight trucks to haul the large containers plus 5 specially constructed & modified Landall trailers and 3 rollback carrier vehicles for the smaller jobs. Current liquidation or re-sale/loan value can be verified at $450,000.",
      "Upside potential of the business currently not being pursued because of the owner’s health which includes transportation of storage van trailers, loaded containers, medium duty rental equipment (forklifts and manual lifts) and ground level storage containers. Quite a bit of competition in this industry but the market for these type of services remains consistent."],
      
      extras: [
        ""
      ],
      images: [
        {
          fields: {
            file: {
              url: business22
            }
          }
        },
        {
          fields: {
            file: {
              url: nopx
            }
          }
        },
        {
          fields: {
            file: {
              url: nopx
            }
          }
        },
        {
          fields: {
            file: {
              url: nopx
            }
          }
        }
      ]
    }
  },
  {
    sys: {
      id: "3"
    },
    fields: {
      name: "Auto Body & Paint",
      slug: "auto repair",
      type: "Auto Repair",
      status: "",
      active: "",
      sold: "",
      otr: "",
      onhold: "",
      offmarket: "1",
      price: 500000,
      adlink: "", 
      //was https://www.bizquest.com/business-for-sale/40-year-old-auto-paint-and-body-shop-north-dallas-tx/BW1945701//
      adlinklabel: `BizBuySell, BizQuest, LoopNet`,
      ndalink: "",
      ndalinklabel: "",
      grosssales: 768789,
      cashflow: 107623,
      inventoryatcost: 8000,
      mthlybuildingrent: 6666.68,
      typeoffinancial: "Seller Financial Statements",
      reportperiodoffinancials: 3,
      ffe: 100000,
      size: 4180,
      established: 40,
      eqValuation: false,
      busValuation: false,
      featured: false,
      relocatable: false,
      homebased: false,
      realestateincluded: false,
      reasonforsale: "retirement",
      description: [
        "BUSINESS SUMMARY & DESCRIPTION ",
        "Opportunity to purchase well established, profitable body shop located in a prime location in north Dallas.   Fully equipped with an experienced staff to handle most any type of auto body work. Services include free drive in estimates, complete auto body repair, complete auto body painting, auto body frame straightening, hail damage repair, complete restoration, glass replacement, ding & dent removal, paint less dent removal and most custom work. Business has built a solid reputation over the past 40 years by giving great service & performing pro-level work. Repeat clientele.",
        "Extremely motivated seller. This is the last of several auto body shops he has owned over the years and is ready to sell and officially go into retirement.",
        "Seller will provide on-site training after the purchase, assist in securing retention of employees and transfer of supplier relationships, provide on-call telephone assistance after completion of the training and sign a no competition agreement.",
        "SALE OF BUSINESS EXTREMELY CONFIDENTIAL!! Employees and customers unaware of seller’s intentions. Business to be seen as a customer only and please act like one. DO NOT CONTACT EMPLOYEES OR OWNER!!  Any closer looks at the business or meetings with the owner are to be done after hours by appointment only through the broker who has all of the necessary information. For further info contact owner’s representative Stocker Travis at 214-233-6481 or email to stocker@dbi-businessadvisors.com"
      ],
      extras: [

      ],
      images: [
        {
          fields: {
            file: {
              url: business16
            }
          }
        },
        {
          fields: {
            file: {
              url: business16A
            }
          }
        },
        {
          fields: {
            file: {
              url: nopx
            }
          }
        },
        {
          fields: {
            file: {
              url: nopx
            }
          }
        }
      ]
    }
  },
  {
    sys: {
      id: "26"
    },
    fields: {
      name: "Italian Restaurant - Richardson",
      slug: "restaurantItalian",
      type: "restaurant",
      status: "",
      active: "",
      sold: "",
      otr: "",
      onhold: "",
      offmarket: "1",
      price: 175000,
      adlink: "",
      adlinklabel: `BizBuySell, BizQuest, LoopNet`,
      ndalink: "",
      ndalinklabel: "",
      grosssales: 807590,
      cashflow: "111346",
      inventoryatcost: "8500",
      mthlybuildingrent: 9757,
      typeoffinancial: "Seller Financial Statements",
      reportperiodoffinancials: 3,
      ffe: 95000,
      size: "3256" ,
      established: 11,
      eqValuation: false,
      busValuation: false,
      featured: false,
      relocatable: false,
      homebased: false,
      realestateincluded: false,
      reasonforsale: "streamlining responsibilities among other owned enterprises",
      description:
        ["BUSINESS DESCRIPTION", 
         "Opportunity to purchase established, profitable Italian style restaurant in prime north Dallas location. Excellent food & service. Fairly self-sufficient operation. Other than outside catering jobs the owner tends to himself, there is a dependable staff in place that pretty much runs the dine in part of the operation without the owner or his family having to work in the restaurant on site.",
      "Extremely motivated seller who needs to free up time and resources to focus on a second full service restaurant he owns as well as consulting responsibilities related to another food operation.",
    "FACILITIES",
  "Fully equipped kitchen set up to prepare any type of food. Equipment in excellent shape. Sale includes van being used for catering events. Current premises lease expires in December of 2024 and the landlord is prepared to negotiate an extension and rent with a buyer prior to a sale taking place.",
"COMPETITION",
"High traffic area near dense residential population base as well as numerous large corporate work places both of which significantly add to the walk in trade and catering opportunities. Well known in the immediate trade area.",
"GROWTH AND EXPANSION",
"After the current owner purchased the business in March of 2014, he & his wife built a substantial business and its reputation by being on site, owner/operators until the operation became self-sufficient at which time they backed off to run their other restaurant/bar. A new buyer can return it to the on-site, owner/operator mode and expand on the current base even further and should be able to push the annual sales to the $1M mark and beyond. Promoting the catering side of the business that come through the restaurant are very profitable.",
"SUPPORT AND TRAINING",
"Seller will provide on-site training after the purchase, assist in securing retention of employees and transfer of supplier relationships, provide on-call telephone assistance after completion of the training and sign a no competition agreement.",
"REASON FOR SELLING",
"Owner needs to free up time and resources to focus on other business interests"],

      extras: [
        ""
      ],
      images: [
        {
          fields: {
            file: {
              url:  business266
            }
          }
        },
        {
          fields: {
            file: {
              url: business26a
            }
          }
        },
        {
          fields: {
            file: {
              url: business26b
            }
          }
        },
        {
          fields: {
            file: {
              url: nopx
            }
          }
        }
      ]
    }
  },
  {
    sys: {
      id: "33"
    },
    fields: {
      name: "motorcycle repair",
      slug: "motocycle-repair",
      type: "repair svcs",
      status: "",
      active: "",
      sold: 1,
      otr: "",
      onhold: "",
      offmarket: "",
      price: 110000,
      adlink: "",
      adlinklabel: `BizBuySell, BizQuest, LoopNet`,
      ndalink: "",
      grosssales: 427822,
      cashflow: 61689,
      inventoryatcost: 75000,
      mthlybuildingrent: 1850,
      typeoffinancial: "Seller Financial Statements",
      reportperiodoffinancials: 3,
      ffe: 50000,
      size: 4500,
      established: 7,
      eqValuation: false,
      busValuation: false,
      featured: false,
      relocatable: false,
      homebased: false,
      realestateincluded: false,
      reasonforsale: "partnership / dissolution",
      description:
        ["Opportunity to purchase a well-established retail motorcycle sales, parts store & repair shop in Arlington, TX.",
          "This business is well known in the area with over 18 years in the motorcycle & powersports industry that originally started out by selling only new OEM and aftermarket parts, accessories and apparel.  There is a full-service center that has the capability to offer simple tire changes to full motor rebuilds.  The business stocks thousands of new parts, accessories & apparel which includes helmets, jackets and gloves for motorcycles,ATVs and UTVs.  Licensed dealer for Koolster and ODES dirt bikes. Great product mix which has created a diverse group of customers from younger to middle aged riders.",
          "Thousands of used motorcycle parts which allows customers the opportunity to save money and buy in stock used parts over new if they so choose. In business for 18 + years with a well-established base of repeat customers. Industry specific software that manages all aspects of the business and houses all customer information. An excellent web presence with glowing reviews across all review sites.",
          "Very motivated seller. Has another high paying full time and cannot devote the time or energy necessary to operate this type of business successfully. Needs a full time, promotion/marketing minded, on site, owner/operator to be viable. Being an absentee owner and the high labor expense associated with that caused the operating loss number for the first 6 months of 2021. There are currently no full time mechanics on board. MAKE ALL REASONABLE OFFERS!!  TERMS AVAILABLE TO THE RIGHT PARTY!! ",
          "Seller will provide on-site training after the purchase, assist in securing retention of employees and transfer of supplier relationships, provide on-call telephone assistance after completion of the training and sign a no competition agreement.",
          "SALE OF BUSINESS NOT CONFIDENTIAL. Business can be seen anytime as a customer only. See below for name and location.  Any closer looks at the business or meetings with the owner are to be done by appointment only through the broker who has all of the necessary information. For further info contact owner’s representative Stocker Travis at 214-725-2775 or email to Stocker@dbi-businessadvisors.com."],
      extras: [
        ""
      ],
      images: [
        {
          fields: {
            file: {
              url: business17
            }
          }
        },
        {
          fields: {
            file: {
              url: nopx
            }
          }
        },
        {
          fields: {
            file: {
              url: nopx
            }
          }
        },
        {
          fields: {
            file: {
              url: nopx
            }
          }
        }
      ]
    }
  },
  {
    sys: {
      id: "42"
    },
    fields: {
      name: "food & beverage",
      slug: "coffeeshop",
      type: "food & beverage",
      status: "",
      active: "",
      sold: "",
      otr: "",
      onhold: "",
      offmarket: 1,
      price: 650000,
      adlink: "",
      adlinklabel: `BizBuySell, BizQuest, LoopNet`,
      ndalink: "",
      grosssales: 380000,
      cashflow: 199900,
      inventoryatcost: 4000,
      mthlybuildingrent: 4000,
      typeoffinancial: "Seller Financial Statements",
      reportperiodoffinancials: 3,
      ffe: 150000,
      size: 572,
      established: 7,
      eqValuation: false,
      busValuation: false,
      featured: false,
      relocatable: false,
      homebased: false,
      realestateincluded: false,
      reasonforsale: "changing careers",
      description:
        ['BUSINESS SUMMARY & DESCRIPTION - Coffee Shop for Sale in North Dallas- Starbucks brand - Serious Cash Buyers Only  Unique opportunity.Located in major hotel chain.Must have food & beverage experience and provide proof of ability to purchase.Financials from Seller Financial Statements  Business hours Monday – Sunday 6: 00 a.m.– 8: 00 p.m. 4 Employees.The information contained in this marketing is subject to change, corrections, or withdrawal without notice.Information provided herein has been provided by the Seller and not by Broker.The Seller has listed this business strictly as an asset transaction.This is not an offer to sell securities.This is not a solicitation to sell securities.Listing Business Broker Firm: Diversified Business Investments.Contact Stocker Travis, Principal at 214 - 725 - 2775 or Stocker@libertyexchange.com  FACILITIES - Approximately 572 sq.ft., $4, 000 Monthly Rent.Lease expires December 31, 2025.  Contact Email: stocker@libertyexchange.com'],
      extras: [
        ""
      ],
      images: [
        {
          fields: {
            file: {
              url: img4
            }
          }
        },
        {
          fields: {
            file: {
              url: businesscoffee
            }
          }
        },
        {
          fields: {
            file: {
              url: nopx
            }
          }
        },
        {
          fields: {
            file: {
              url: nopx
            }
          }
        }
      ]
    }
  },
  {
    sys: {
      id: "13"
    },
    fields: {
      name: "Document Mgmt VAR",
      slug: "computer",
      type: "service",
      status: "",
      active: "",
      sold: "",
      otr: "",
      onhold: 1,
      offmarket: "",
      price: 150000,
      adlink: "",
      adlinklabel: `BizBuySell, BizQuest, LoopNet`,
      ndalink: "",
      // https://www.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=b9ff4f7d-a0b7-486f-90ac-079044449fce&env=na1&acct=b0ae5c00-9705-4ddb-b3c9-7eda5fe928ce&v=2",
      grosssales: 444454,
      cashflow: 36855,
      inventoryatcost: 0,
      mthlybuildingrent: 0,
      typeoffinancial: "Seller Financial Statements",
      reportperiodoffinancials: 3,
      ffe: 50000,
      size: 0,
      established: 5,
      eqValuation: true,
      busValuation: false,
      featured: false,
      relocatable: true,
      homebased: true,
      realestateincluded: false,
      reasonforsale: "retirement",
      description: [
        "BUSINESS DESCRIPTION",
        "This is an excellent opportunity to purchase a profitable going concern in the content management & digital transformation consulting industry. The company was formed to provide cost-effective expertise to implement knowledge management, archival tools and workflow options to customers with the need for simple, inexpensive, effective tools to enable their staff to create, manage, and share content with each other and their customers and partners. Services include document & content management, data capture and extraction, document conversion, robotic process automation, process improvement, the creation of forms and templates and Xerox DocuShare customization. Sale of the business includes an extensive list of hardware, software, documentation, and databases that allows the business the capability to efficiently perform all of the services offered, as well a customer list and a professionally built website and online store. They have roughly one hundred customers which range from small operations to Fortune 100 companies, most with annual maintenance renewal contracts. Trademarks, internally developed products with source code, and websites may be transferred. This is a highly organized company that documents well to provide a smooth transition with significant historical customer information.",
        "Major vendors include ABBYY and Xerox. The company is one of the largest Xerox DocuShare value-added resellers and integrators in the country. The majority of what the business sells to its customers has been perpetual software licenses and benefits from annual support and maintenance renewals. SaaS/Cloud offerings are also available for sale. Significant revenue comes from professional services for support, installation, implementation, and projects.",
        "The owner has been in the industry for 40 years now (20 years with Xerox in various systems organizations) and has owned this business for nearly nineteen years starting it from nothing and is now ready to retire. Price recently reduced for a quick sale. The industry has a bright future and is growing very rapidly. With its current infrastructure the business can be increased significantly with little to no additional investment in equipment and personnel.",
        "https://www.zdnet.com/article/digital-transformation-is-changing-heres-what-comes-next/?ftag=TRE-03-10aaa6b&bhid=%7B%24external_id%7D&mid=%7B%24MESSAGE_ID%7D&cid=%7B%24contact_id%7D&eh=%7B%24CF_emailHash%7D",
        "Please copy & paste above link into your browser.",
        "Seller will provide training of company products and resources after the purchase, assist in securing retention of employees, transfer of vendor relationships, provide on-call telephone assistance after completion of the training, and sign a no competition agreement.",
        "Business hours Monday - Friday 8:00 am – 5:00 pm. Two employees",
        "The information contained in this marketing is subject to change, corrections, or withdrawal without notice. Information provided herein has been provided by the Seller and not by Broker.",
        "The Seller has listed this business strictly as an asset transaction. This is not an offer to sell securities. This is not a solicitation to sell securities.",
        "Listing Business Broker Firm: Diversified Business Investments. Contact Stocker Travis, Principal at 214-233-6481 or stocker@dbi-businessadvisors.com",
      ],
      extras: [

      ],
      images: [
        {
          fields: {
            file: {
              url: databiz
            }
          }
        },
        {
          fields: {
            file: {
              url: nopx
            }
          }
        },
        {
          fields: {
            file: {
              url: nopx
            }
          }
        },
        {
          fields: {
            file: {
              url: nopx
            }
          }
        }
      ]
    }
  },
   {
    sys: {
      id: "14"
    },
    fields: {
      name: "automotive parts",
      slug: "automotive-parts",
      type: "automotive",
      status: "",
      active: "",
      sold: "",
      otr: "",
      onhold: "",
      offmarket: "1",
      price: 300000,
      adlink: "",
      adlinklabel: `BizBuySell, BizQuest, LoopNet`,
      ndalink: "",
      grosssales: 400000,
      cashflow: 67500,
      inventoryatcost: 4000,
      mthlybuildingrent: 5000,
      typeoffinancial: "Seller Financial Statements",
      reportperiodoffinancials: 3000,
      ffe: 45000,
      size: 4090,
      established: 10,
      eqValuation: false,
      busValuation: false,
      featured: false,
      relocatable: false,
      homebased: false,
      realestateincluded: false,
      reasonforsale: "partnership - dissolution",
      description:
        ["Private Sale"],
      extras: [
        ""
      ],
      images: [
        {
          fields: {
            file: {
              url: img8
            }
          }
        },
        {
          fields: {
            file: {
              url: businessautomaint1
            }
          }
        },
        {
          fields: {
            file: {
              url: businessautomaint2
            }
          }
        },
        {
          fields: {
            file: {
              url: businessautomaint3
            }
          }
        }
      ]
    }
  },
  {
    sys: {
      id: "15"
    },
    fields: {
      name: "automotive detailing",
      slug: "automotive-detailing",
      type: "automotive",
      price: 350000,
      status: "",
      active: "",
      sold: "",
      otr: "",
      onhold: "",
      offmarket: "1",
      adlink: "",
      adlinklabel: `BizBuySell, BizQuest, LoopNet`,
      ndalink: "",
      grosssales: 300000,
      cashflow: 70500,
      inventoryatcost: 12000,
      mthlybuildingrent: 4000,
      typeoffinancial: "Seller Financial Statements",
      reportperiodoffinancials: 3,
      ffe: 5000,
      size: 2990,
      established: 10,
      eqValuation: false,
      busValuation: false,
      featured: false,
      relocatable: false,
      homebased: false,
      realestateincluded: false,
      reasonforsale: "partnership / dissolution",
      description:
        ["Internal Restruturing"],
      extras: [
        ""
      ],
      images: [
        {
          fields: {
            file: {
              url: img9
            }
          }
        },
        {
          fields: {
            file: {
              url: businessautomaint1
            }
          }
        },
        {
          fields: {
            file: {
              url: businessautomaint2
            }
          }
        },
        {
          fields: {
            file: {
              url: businessautomaint3
            }
          }
        }
      ]
    }
  },
  {
    sys: {
      id: "16"
    },
    fields: {
      name: "automotive repair",
      slug: "automotive-repair",
      type: "automotive",
      price: 400000,
      status: "",
      active: "",
      sold: "",
      otr: "",
      onhold: "",
      offmarket: "1",
      adlink: "",
      adlinklabel: `BizBuySell, BizQuest, LoopNet`,
      ndalink: "",
      grosssales: 400000,
      cashflow: 67500,
      inventoryatcost: 4000,
      mthlybuildingrent: 5000,
      typeoffinancial: "Seller Financial Statements",
      reportperiodoffinancials: 3,
      ffe: 45000,
      size: 3990,
      established: 6,
      eqValuation: false,
      busValuation: false,
      featured: false,
      relocatable: false,
      homebased: false,
      realestateincluded: false,
      reasonforsale: "downsizing",
      description:
        ["Private Listing - contact advisor"],
      extras: [
        ""
      ],
      images: [
        {
          fields: {
            file: {
              url: img10
            }
          }
        },
        {
          fields: {
            file: {
              url: businessautorep1
            }
          }
        },
        {
          fields: {
            file: {
              url: businessautorep2
            }
          }
        },
        {
          fields: {
            file: {
              url: businessautorep3
            }
          }
        }
      ]
    }
  },
  {
    sys: {
      id: "17"
    },
    fields: {
      name: "auto maintenance",
      slug: "automotive-maintenance",
      type: "automotive",
      price: 500000,
      status: "",
      active: "",
      sold: "",
      otr: "",
      onhold: "",
      offmarket: "1",
      adlink: "",
      adlinklabel: `BizBuySell, BizQuest, LoopNet`,
      ndalink: "",
      grosssales: 400000,
      cashflow: 67500,
      inventoryatcost: 4000,
      mthlybuildingrent: 5000,
      typeoffinancial: "Seller Financial Statements",
      reportperiodoffinancials: 3,
      ffe: 45000,
      size: 3990,
      established: 10,
      eqValuation: false,
      busValuation: false,
      featured: false,
      relocatable: false,
      homebased: false,
      realestateincluded: false,
      reasonforsale: "retirement",
      description:
        [""],
      extras: [
        "",
      ],
      images: [
        {
          fields: {
            file: {
              url: img11
            }
          }
        },
        {
          fields: {
            file: {
              url: businessautorep1
            }
          }
        },
        {
          fields: {
            file: {
              url: businessautorep2
            }
          }
        },
        {
          fields: {
            file: {
              url: businessautorep3
            }
          }
        }
      ]
    }
  },
  {
    sys: {
      id: "12"
    },
    fields: {
      name: "Retail - Jewelry",
      slug: "retail jewelry",
      price: 75000,
      type: "retail",
      status: "",
      active: "",
      sold: "",
      otr: "",
      offmarket: 1,
      onhold: "",
      adlink: "",
      adlinklabel: `BizBuySell, BizQuest, LoopNet`,
      ndalink: "",
      ndalinklabel: "",
      grosssales: 252700,
      cashflow: 47200,
      inventoryatcost: 10000,
      mthlybuildingrent: 4500,
      typeoffinancial: "Seller Financial Statements",
      reportperiodoffinancials: 3,
      ffe: 27500,
      size: 875,
      established: 5,
      eqValuation: false,
      busValuation: false,
      featured: false,
      relocatable: false,
      homebased: false,
      realestateincluded: false,
      reasonforsale: "partnership / dissolution",
      description: [
        "BUSINESS SUMMARY & DESCRIPTION ",
        "** Price Reduced to $75, 000** MUST SELL NOW ** ",
        "Opportunity to purchase an established profitable watch and jewelry repair business that is part of the world’s largest franchise in that particular industry.  Concept was introduced over 30 years ago and has grown to 150 retail locations in the U.S.and Canada.  Always ranked by Entrepreneur’s Franchise 500. Perfected system for delivering fast, efficient, professional repair work on jewelry and watches for men and woman.  Proven system with a franchiser that will provide training, on-going support and efficient promotion and advertising methods.",
        "Having experience in this business is NOT a prerequisite for success.  Stores located conveniently in high traffic areas of regional shopping malls either in highly visible kiosks or through an inline retail front.  Services include chain smoldering, remounting, ring sizing, engraving(extremely high profit margins), jewelry cleanings, watchbands, mountings, loose stones, smart phone repair and watch batteries replacement.",
        "Financials – Seller financial statements",
        "Business hours - Monday - Saturday 11: 00 am – 9: 00 pm; Sun 12 noon – 6: 00 pm",
        "2 employees ",
        "EXTREMELY MOTIVATED SITUATION! The seller has been employed as a jeweler with the franchise as either an owner or an employee for over 30 years and is ready to retire for health reasons. This business has always made a solid income and will continue to do so but the owner has grown weary.  Ready to make a move now so please make any reasonable offer.",
        "This business needs to be operated by a younger, more businesses minded individual. The owner can be replaced by a jeweler at a pay scale of $18 - 21 / hour with a 30 - 35 hours work / week maximum.  Sale will require franchiser approval and a transfer fee of $5,000 to be paid by the buyer which will include 1 week training in all aspects of the business (room, board and airfare not included).",
        "A 6 % royalty is paid monthly based on the gross revenues and the franchisee is required to spend an additional 2 % of gross revenues on advertising in the trade / market area as defined by the franchisee.",
        "The information contained in this marketing is subject to change, corrections, or withdrawal without notice.  Information provided herein has been provided by the Seller and not by Broker.  The Seller has listed this business strictly as an asset transaction.  This is not an offer to sell securities.  This is not a solicitation to sell securities",
        "Listing Firm: Diversified Business Investments, Contact Stocker Travis, Principal at 214 - 725 - 2775 or Stocker@dbi-businessadvisors.com",
        "Lease expires April 30, 2022",
        "MARKET OUTLOOK & COMPETITION - Excellent for the right individual",
        "GROWTH POTENTIAL & EXPANSION - Outside what is required by the franchiser, the seller does little to no advertising.",
        "SUPPORT & TRAINING - Seller will train at no cost to buyer for two weeks.",
        "REASON FOR SALE - Health / retirement."
      ],
      extras: [
        "Inventory included in asking price",
        "Years established 2",
        "Hours Mon - Sat 11:00 am - 9:00 pm   Sunday 12 noon - 6:00 pm",
      ],
      images: [
        {
          fields: {
            file: {
              url: img1
            }
          }
        },
        {
          fields: {
            file: {
              url: businessffx1
            }
          }
        },
        {
          fields: {
            file: {
              url: nopx
            }
          }
        },
        {
          fields: {
            file: {
              url: nopx
            }
          }
        }
      ]
    }
  },
  /*  {
     sys: {
       id: "12"
     },
     fields: {
       name: "winery",
       slug: "winery-business",
       type: "winery",
       price: 600000,
       adlink: 235000,
       grosssales: 200000,
       cashflow: 100000,
       inventoryatcost: 4000,
       mthlybuildingrent: 0,
       typeoffinancial: "Seller Financial Statements",
       reportperiodoffinancials: 5,
       FFE: 35000,
       size: 3990,
       established: 3,
       eqValuation: false,
       busValuation: false,
       featured: false,
       relocatable: false,
       homebased: false,
       realestateincluded: true,
       reasonforsale: "downsizing",     
       description:
         ["Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page avant impression. Le Lorem Ipsum est le faux texte standard de l'imprimerie depuis les années 1500, quand un imprimeur anonyme assembla ensemble des morceaux de texte pour réaliser un livre spécimen de polices de texte. Il n'a pas fait que survivre cinq siècles, mais s'est aussi adapté à la bureautique informatique, sans que son contenu n'en soit modifié. Il a été popularisé dans les années 1960 grâce à la vente de feuilles Letraset contenant des passages du Lorem Ipsum, et, plus récemment, par son inclusion dans des applications de mise en page de texte"],
       extras: [
         "Years established 20",
         "Seller will train at no cost to buyer for two weeks",
         "Cash flow is discretionary earnings",
         "Motivated Sellers",
         "Mature business model in niche industry",
         "Includes import business",
         "Possible expension of lines of business through internet"
       ],
       images: [
         {
           fields: {
             file: {
               url: business1
             }
           }
         },
         {
           fields: {
             file: {
               url: business2
             }
           }
         },
         {
           fields: {
             file: {
               url: business3
             }
           }
         },
         {
           fields: {
             file: {
               url: business4
             }
           }
         }
       ]
     }
   } */
];

